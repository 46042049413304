.btn {
    @apply font-semibold px-6 py-4 rounded-full border border-transparent text-white;

    &.primary {
        @apply bg-orange-500 hover:bg-orange-600;
    }

    &.outline-primary {
        @apply bg-transparent text-orange-500 border border-orange-500 hover:bg-orange-600 hover:text-white hover:border-transparent;
    }
}
